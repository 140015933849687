<template>
   <main>
      <section class="login-area py-md-5 py-3">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="basic-login pt-50 pb-50">
                     <h3 class="text-center mb-60">Entrar</h3>
                     <form action="javascript:;">
                        <label for="username">Nome de usuário <span>**</span></label>
                        <input id="username" type="text" placeholder="Insira o nome de usuário..." v-model="dados.username" />

                        <label for="pass">Senha <span>**</span></label>
                        <input id="pass" type="password" placeholder="Insira a senha..." v-model="dados.senha" />

                        <button class="t-y-btn w-100" @click="login">Entrar</button>
                        <div class="or-divide"><span>ou</span></div>
                        <router-link to="/registro" class="t-y-btn t-y-btn-grey w-100">Registrar</router-link>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

export default {
	name: 'Login',
   data: function() {
		return {
			dados: {'username': '', 'senha': ''}
		}
	},
   methods: {
      login : function () {
         if (this.dados.username.length > 0 && this.dados.senha.length > 0) {
            this.$store.dispatch('login', {'usuario': this.dados.username, 'senha': this.dados.senha});
         }
      }
   }
}

</script>